import createDataContext from './createDataContext';
import { api } from '../api/api';

const productSelectorReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				productSelector: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadProductSelector = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get(`/productSelectors/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load product selector.',
		});
	}
};

const updateProductSelector = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/productSelectors/${id}`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to update product selector.',
		});
	}
};

const createProductSelector = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/productSelectors`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to create product selector.',
		});
	}
};

const deleteProductSelector = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.delete(`/productSelectors/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to delete product selector.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	productSelectorReducer,
	{
		loadProductSelector,
		createProductSelector,
		updateProductSelector,
		deleteProductSelector,
	},
	{
		productSelector: null,
		error: '',
		loading: false,
	}
);
