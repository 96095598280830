import createDataContext from './createDataContext';
import { api } from '../api/api';

const benefitGroupsReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				benefitGroups: action.payload,
			};
		case 'CREATE':
			return {
				...state,
				loading: false,
				benefitGroups: [...state.benefitGroups, action.payload],
			};
		case 'UPDATE':
			const newBenefitGroups = state.benefitGroups.map((item) => {
				if (item._id === action.payload._id) {
					return action.payload;
				}

				return item;
			});

			return {
				...state,
				loading: false,
				benefitGroups: newBenefitGroups,
			};
		case 'DELETE':
			return {
				...state,
				loading: false,
				benefitGroups: state.benefitGroups.filter((item) => item._id !== action.payload),
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadBenefitGroups = (dispatch) => async () => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get('/benefitGroups', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load benefitGroups',
		});
	}
};

const createBenefitGroup = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/benefitGroups`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		dispatch({
			type: 'CREATE',
			payload: response.data,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to create benefit.',
		});
	}
};

const updateBenefitGroup = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/benefitGroups/${id}`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		dispatch({
			type: 'UPDATE',
			payload: response.data,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to update benefit.',
		});
	}
};

const deleteBenefitGroup = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.delete(`/benefitGroups/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'DELETE',
			payload: id,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to delete benefit.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	benefitGroupsReducer,
	{
		loadBenefitGroups,
		createBenefitGroup,
		updateBenefitGroup,
		deleteBenefitGroup,
	},
	{
		benefitGroups: [],
		error: '',
		loading: false,
	}
);
