/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { Context as UserContext } from '../../context/UserContext';

import NotFound from '../NotFound/NotFound';
import UrlParamsPage from '../UrlParamsPage/UrlParamsPage';
import UsersPage from '../UsersPage/UsersPage';
import LoginPage from '../LoginPage/LoginPage';
import StickyProducts from '../StickyProducts/StickyProducts';
import ProductPages from '../ProductPages/ProductPages';
import ProductPage from '../ProductPage/ProductPage';
import SpecialOffers from '../SpecialOffersPage/SpecialOffersPage';
import SpecialOffer from '../SpecialOfferPage/SpecialOfferPage';
import OrdersPage from '../OrdersPage/OrdersPage';
import BenefitGroupsPage from '../BenefitGroupsPage/BenefitGroupsPage';
import ProductSelectors from '../ProductSelectors/ProductSelectors';
import ProductSelector from '../ProductSelector/ProductSelector';
import CrossSellsPage from '../CrossSellsPage/CrossSellsPage';
import CrossSellPage from '../CrossSellPage/CrossSellPage';
import WebsitesPage from '../WebsitesPage/WebsitesPage';
import BannersPage from '../BannersPage/BannersPage';

export default function App() {
	const history = useHistory();
	const [pageName, setPageName] = useState('');

	const {
		state: { user, isTokenValid },
		validate,
		logout,
	} = useContext(UserContext);

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			return history.push('/login');
		}

		if (localStorage.getItem('token')) {
			validate({ token: localStorage.getItem('token') });
		}
	}, []);

	useEffect(() => {
		if (!isTokenValid) {
			history.push('/login');
		}
	}, [isTokenValid]);

	async function handleLogout(id) {
		await logout(id);
		localStorage.removeItem('token');
		history.push('/login');
	}

	return (
		<>
			<Header pageName={pageName} user={user} onLogout={handleLogout} />
			<Switch>
				<Route path='/login'>
					<LoginPage />
				</Route>

				<Route exact path='/urlParams'>
					<UrlParamsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/banners'>
					<BannersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/productPages'>
					<ProductPages onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/productPages/:id'>
					<ProductPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/productSelectors'>
					<ProductSelectors onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/productSelectors/:id'>
					<ProductSelector onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/crossSells'>
					<CrossSellsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/crossSells/:id'>
					<CrossSellPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/stickyProducts'>
					<StickyProducts onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/specialOffers'>
					<SpecialOffers onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/specialOffers/:id'>
					<SpecialOffer onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/websites'>
					<WebsitesPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/benefitGroups'>
					<BenefitGroupsPage onRender={(name) => setPageName(name)} />
				</Route>

				<Route exact path='/users'>
					<UsersPage onRender={(name) => setPageName(name)} />
				</Route>

				<Route exact path='/'>
					<OrdersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</>
	);
}
