import React from 'react';
import { Link } from 'react-router-dom';
import image from './404.jpeg';

const NotFound = () => (
	<div
		style={{
			width: '600px',
			maxWidth: '100%',
			margin: '30px auto',
			padding: '20px',
		}}
	>
		<h1>404 - Page Not Found!</h1>
		<div>
			<img src={image} alt='' />
		</div>
		<Link to='/'>Go Home</Link>
	</div>
);

export default NotFound;
