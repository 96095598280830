import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import { Context as StickyProductsContext } from '../../context/StickyProductsContext';

import GalleryItem from '../../components/GalleryItem/GalleryItem';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { getSubscriptionPrice } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	products: {
		display: 'flex',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		'& $textField': {
			marginRight: theme.spacing(2),
			marginBottom: 0,
			[theme.breakpoints.down('xs')]: {
				marginRight: 0,
			},
		},
	},
	productRow: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		'&:last-child': {
			marginBottom: 0,
		},
	},
	iconsGroup: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	productsIcon: {
		alignSelf: 'center',
		marginLeft: theme.spacing(1),
	},
	pageActionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing(2),
	},
	productSelect: {
		width: '100%',
		maxWidth: '100%',
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1),
	},
	variantSelect: {
		width: '100%',
		maxWidth: '100%',
		marginTop: theme.spacing(1),
	},
}));

export default function SpecialOfferPage({ onRender }) {
	// VARS
	const { id } = useParams();
	const history = useHistory();
	const classes = useStyles();

	// STATE
	const [name, setName] = useState('');
	const [headline, setHeadline] = useState('');
	const [discount, setDiscount] = useState('');
	const [offerId, setOfferId] = useState('');
	const [gallery, setGallery] = useState([]);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { specialOffer, loading: specialOfferLoading, error: specialOfferError },
		loadSpecialOffer,
		updateSpecialOffer,
		createSpecialOffer,
	} = useContext(SpecialOfferContext);

	const {
		state: { stickyProducts, stickyProductsLoading, stickyProductsError },
		loadStickyProducts,
	} = useContext(StickyProductsContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadSpecialOffer(id);
			loadStickyProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (specialOffer) {
			onRender(`Special offer - ${specialOffer.name}`);
			// Set values after special offer is loaded
			setName(specialOffer.name);
			setHeadline(specialOffer.headline);
			setGallery(specialOffer.gallery);
			setDiscount(specialOffer.discount);
			setOfferId(specialOffer.offerId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specialOffer]);

	if (specialOfferError || stickyProductsError) {
		return <Error error={specialOfferError || stickyProductsError} />;
	}

	if (!specialOffer) {
		return null;
	}

	function handleSpecialOfferUpdate(update) {
		updateSpecialOffer(id, update);
	}

	async function fetchProductVariants(id) {
		const config = {
			method: 'get',
			url: `https://manna.sticky.io/api/v2/products/${id}/variants`,
			headers: {
				'Content-Type': 'application/json',
			},
			auth: {
				username: process.env.REACT_APP_STICKY_USERNAME,
				password: process.env.REACT_APP_STICKY_PASSWORD,
			},
		};

		try {
			const response = await axios(config);

			return response.data.data;
		} catch (error) {
			return error;
		}
	}

	function getProductVariants(rawVariants) {
		const result = {
			name: '',
			values: [],
		};

		if (!rawVariants) {
			return result;
		}

		rawVariants.forEach((item) => {
			result.name = item.attributes[0].attribute.name;
			result.values.push(item.attributes[0].attribute.option.name);
		});

		return result;
	}

	function renderProducts() {
		async function handleChange(event) {
			let variants = null;
			const selectedProduct = stickyProducts.find((item) => item.id === event.target.value);
			const response = await fetchProductVariants(selectedProduct.id);
			variants = getProductVariants(response);

			handleSpecialOfferUpdate({
				product: {
					id: selectedProduct.id,
					name: selectedProduct.name,
					variants,
					variantName: selectedProduct.variant_name,
					slug: selectedProduct.slug,
					image: selectedProduct.images.length > 0 ? selectedProduct.images[0].path : '',
					sku: selectedProduct.sku,
					msrp: selectedProduct.price * 1,
					oneTimePrice: selectedProduct.sale_price * 1 || selectedProduct.price * 1,
					subscriptionPrice: getSubscriptionPrice(
						selectedProduct.sale_price * 1 || selectedProduct.price * 1
					),
					quantity: 1,
					isSpecialOffer: true,
				},
			});
		}

		function handleVariantChange(event) {
			handleSpecialOfferUpdate({
				product: {
					...specialOffer.product,
					variantValue: event.target.value,
				},
			});
		}

		return (
			<Box>
				<FormControl size='small' className={classes.productSelect}>
					<InputLabel>Sticky Product</InputLabel>
					<Select label='Sticky Product' value={specialOffer.product.id} onChange={handleChange}>
						{stickyProducts.map((item, index) => (
							<MenuItem key={index} value={item.id} name={item.name}>
								{`[${item.id}] - ${item.name}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{specialOffer.product.variants && specialOffer.product.variants.name && (
					<FormControl size='small' className={classes.variantSelect}>
						<InputLabel>{specialOffer.product.variants.name}</InputLabel>
						<Select
							value={specialOffer.product.variantValue}
							label={specialOffer.product.variants.name}
							onChange={handleVariantChange}
						>
							{specialOffer.product.variants.values.map((item, index) => (
								<MenuItem key={index} value={item} name={item}>
									{item}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</Box>
		);
	}

	function renderHiddenForProducts() {
		const options = stickyProducts.map((item) => ({
			id: item.id,
			name: `[${item.id}] - ${item.name}`,
		}));

		return (
			<Box className={classes.select}>
				<MultiSelect
					label='Hidden for Products:'
					optionLabel='name'
					options={options}
					value={specialOffer.hiddenForProducts}
					className={classes.select}
					onChange={(values) =>
						handleSpecialOfferUpdate({
							hiddenForProducts: values,
						})
					}
				/>
			</Box>
		);
	}

	function renderGallery() {
		function handleAddGalleryItem() {
			handleSpecialOfferUpdate({
				gallery: [
					...gallery,
					{
						source: '',
						thumbnail: '',
						type: 'image',
					},
				],
			});
		}

		function handleUpdateGalleryItem(update, index) {
			const newGallery = [...gallery];
			const entries = Object.entries(update)[0];

			newGallery[index][entries[0]] = entries[1];
			setGallery(newGallery);
			handleSpecialOfferUpdate({ gallery: newGallery });
		}

		function handleDeleteGalleryItem(itemIndex) {
			handleSpecialOfferUpdate({
				gallery: gallery.filter((item, index) => index !== itemIndex),
			});
		}

		return (
			<>
				<Typography variant='subtitle2' gutterBottom>
					Gallery
				</Typography>
				{gallery.map((item, index) => (
					<Box className={classes.products} key={index}>
						<GalleryItem
							index={index}
							source={item.source}
							type={item.type}
							thumbnail={item.thumbnail}
							onUpdate={handleUpdateGalleryItem}
						/>
						<Box className={classes.iconsGroup}>
							<IconButton
								color='secondary'
								aria-label='Delete'
								component='span'
								size='small'
								onClick={() => handleDeleteGalleryItem(index)}
								className={classes.productsIcon}
							>
								<DeleteIcon />
							</IconButton>
							<IconButton
								color='primary'
								aria-label='Add'
								component='span'
								size='small'
								onClick={() => handleAddGalleryItem()}
								className={classes.productsIcon}
							>
								<AddIcon />
							</IconButton>
						</Box>
					</Box>
				))}
				{!gallery.length && (
					<Box className={classes.iconsGroup}>
						<IconButton
							color='primary'
							aria-label='Add'
							component='span'
							size='small'
							onClick={() => handleAddGalleryItem()}
							className={classes.productsIcon}
						>
							<AddIcon />
						</IconButton>
					</Box>
				)}
			</>
		);
	}

	function renderPageActionButtons() {
		async function onCloneClick() {
			const clone = specialOffer;
			delete clone.createdAt;
			delete clone._id;
			delete clone.__v;
			clone.name += ' clone';

			try {
				const created = await createSpecialOffer(clone);
				history.push({});
				history.push(`/specialOffers/${created._id}`);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('error: ', error);
			}
		}

		return (
			<Box className={classes.pageActionButtons}>
				<Button
					variant='contained'
					color='primary'
					endIcon={<ContentCopyIcon />}
					onClick={() => onCloneClick()}
				>
					Clone
				</Button>
			</Box>
		);
	}

	return (
		<>
			{(specialOfferLoading || stickyProductsLoading) && <LinearProgress className={classes.progress} />}
			<Container maxWidth='lg' className={classes.container}>
				{renderPageActionButtons()}
				<Paper className={classes.paper}>
					<TextField
						label='Id'
						value={specialOffer._id}
						fullWidth
						InputProps={{
							readOnly: true,
						}}
						className={classes.textField}
					/>
					<TextField
						label='Name'
						value={name}
						onChange={(e) => setName(e.target.value)}
						onBlur={() =>
							handleSpecialOfferUpdate({
								name,
							})
						}
						fullWidth
						className={classes.textField}
					/>

					<TextField
						label='Headline'
						value={headline}
						onChange={(e) => setHeadline(e.target.value)}
						onBlur={() =>
							handleSpecialOfferUpdate({
								headline,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Discount %'
						helperText='Example: 20'
						value={discount}
						onChange={(e) => setDiscount(e.target.value)}
						onBlur={() =>
							handleSpecialOfferUpdate({
								discount,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Sticky offer id'
						value={offerId}
						onChange={(e) => setOfferId(e.target.value)}
						onBlur={() =>
							handleSpecialOfferUpdate({
								offerId,
							})
						}
						fullWidth
						className={classes.textField}
					/>

					<FormGroup className={classes.textField}>
						<FormControlLabel
							control={
								<Switch
									value={specialOffer.active}
									checked={specialOffer.active}
									onChange={(e) =>
										handleSpecialOfferUpdate({
											active: e.target.checked,
										})
									}
								/>
							}
							label='Active'
						/>
					</FormGroup>
					<ChipsList
						items={specialOffer.benefits}
						onUpdate={(newItems) => handleSpecialOfferUpdate({ benefits: newItems })}
						title='Benefits'
					/>
					{renderGallery()}
					{renderProducts()}
					{renderHiddenForProducts()}
				</Paper>
			</Container>
		</>
	);
}

SpecialOfferPage.propTypes = {
	onRender: PropTypes.func,
};
