import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import ChipsList from '../../components/ChipsList/ChipsList';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as ProductSelectorContext } from '../../context/ProductSelectorContext';
import { Context as StickyProductsContext } from '../../context/StickyProductsContext';

import { FormControl, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { getProductDefaultImage } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	products: {
		display: 'flex',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	product: {
		width: '33.33%',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		padding: theme.spacing(2),
		border: '1px solid #c4c4c4',
		borderRadius: '5px',
		'&:first-child': {
			marginLeft: 0,
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
	productImage: {
		marginBottom: theme.spacing(1),
		textAlign: 'center',
	},
	titleSelect: {
		marginBottom: theme.spacing(3),
	},
}));

export default function ProductSelectorPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();

	// STATE
	const [name, setName] = useState('');

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { productSelector, loading: productSelectorLoading, error: productSelectorError },
		loadProductSelector,
		updateProductSelector,
	} = useContext(ProductSelectorContext);

	const {
		state: { stickyProducts, stickyProductsLoading, stickyProductsError },
		loadStickyProducts,
	} = useContext(StickyProductsContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadProductSelector(id);
			loadStickyProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (productSelector) {
			onRender(`Product Selector - ${productSelector.name}`);
			// Set values after productSelector is loaded
			setName(productSelector.name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productSelector]);

	if (productSelectorError || stickyProductsError) {
		return <Error error={productSelectorError || stickyProductsError} />;
	}

	if (!productSelector) {
		return null;
	}

	function handleProductSelectorUpdate(update) {
		updateProductSelector(id, update);
	}

	function renderProductsSelect() {
		const options = stickyProducts
			.map((item) => ({
				id: item.id,
				name: item.name,
				slug: item.slug,
				images: item.images,
				sku: item.sku,
				price: item.price,
				category: item.category,
				sale_price: item.sale_price,
				title: '',
				optionName: `[${item.id}] - ${item.name} `,
			}))
			.sort((a, b) => a.id - b.id);

		return (
			<Box className={classes.select}>
				<MultiSelect
					label='Sticky products'
					optionLabel='optionName'
					options={options}
					value={productSelector.products}
					className={classes.select}
					onChange={(values) =>
						handleProductSelectorUpdate({
							products: values,
						})
					}
				/>
			</Box>
		);
	}

	const Product = ({ item }) => {
		const titleOptions = ['most popular', 'best value'];
		const [billingModelId, setBillingModelId] = useState(item.billingModelId || '');

		function handleSaveProduct(e) {
			const index = productSelector.products.findIndex((product) => product.id === item.id);
			const newProducts = [...productSelector.products];
			newProducts[index][e.target.name] = e.target.value;

			handleProductSelectorUpdate({
				products: newProducts,
			});
		}

		return (
			<div className={classes.product}>
				<TextField
					label='Product id'
					value={item.id}
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					className={classes.textField}
				/>
				<TextField
					label='Product name'
					value={item.name}
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					className={classes.textField}
				/>
				<TextField
					label='Subscription Billing model id'
					name='billingModelId'
					helperText='If empty, the default billing model id (3) will be used.'
					value={billingModelId}
					onChange={(e) => setBillingModelId(e.target.value)}
					fullWidth
					onBlur={(e) => handleSaveProduct(e)}
					className={classes.textField}
				/>

				<FormControl fullWidth className={classes.titleSelect}>
					<InputLabel>Title</InputLabel>
					<Select name='title' value={item.title} label='Title' onChange={handleSaveProduct}>
						<MenuItem value={''}>
							<em>None</em>
						</MenuItem>
						{titleOptions.map((option, index) => (
							<MenuItem value={option} key={index}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div className={classes.productImage}>
					<img height={100} width={100} alt={item.name} src={getProductDefaultImage(item)} />
				</div>
			</div>
		);
	};

	function renderProducts() {
		return (
			<Box className={classes.products}>
				{productSelector.products.map((item, index) => (
					<Product item={item} key={index} />
				))}
			</Box>
		);
	}

	return (
		<>
			{(productSelectorLoading || stickyProductsLoading) && <LinearProgress className={classes.progress} />}
			<Container maxWidth='lg' className={classes.container}>
				<Paper className={classes.paper}>
					<TextField
						label='Id'
						value={productSelector._id}
						fullWidth
						InputProps={{
							readOnly: true,
						}}
						className={classes.textField}
					/>

					<TextField
						label='Name'
						value={name}
						onChange={(e) => setName(e.target.value)}
						onBlur={() =>
							handleProductSelectorUpdate({
								name,
							})
						}
						fullWidth
						className={classes.textField}
					/>
					<ChipsList
						items={productSelector.slugs}
						onUpdate={(newItems) => handleProductSelectorUpdate({ slugs: newItems })}
						title='Slugs'
					/>
					{renderProductsSelect()}
					{renderProducts()}
				</Paper>
			</Container>
		</>
	);
}

ProductSelectorPage.propTypes = {
	onRender: PropTypes.func,
};
