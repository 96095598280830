import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as ProductSelectorsContext } from '../../context/ProductSelectorsContext';
import { Context as ProductSelectorContext } from '../../context/ProductSelectorContext';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	title: {
		marginTop: theme.spacing(4),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function ProductSelectors({ onRender }) {
	const classes = useStyles();
	const history = useHistory();

	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [productSelectorToDelete, setProductSelectorToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { productSelectors, loading: productSelectorsLoading, error: productSelectorsError },
		loadProductSelectors,
	} = useContext(ProductSelectorsContext);

	const {
		state: { productSelector, error: productSelectorError },
		createProductSelector,
		deleteProductSelector,
	} = useContext(ProductSelectorContext);

	// EFFECTS
	useEffect(() => {
		if (user || productSelector) {
			loadProductSelectors();
		}

		onRender('Product selectors');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, productSelector]);

	function handleDeleteProductSelector(id) {
		setDeleteModalOpen(true);
		setProductSelectorToDelete(id);
	}

	async function handleCreateProductSelector() {
		const newProductSelector = await createProductSelector();
		history.push(`/productSelectors/${newProductSelector._id}`);
	}

	if (productSelectorsError || productSelectorError) {
		return <Error error={productSelectorsError || productSelectorError} />;
	}

	if (productSelectorsLoading) {
		return <Loading />;
	}

	return (
		<>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this product selector?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteProductSelector(productSelectorToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => handleCreateProductSelector()}
					>
						Add
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Id</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Slugs</TableCell>
								<TableCell align='right'>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{productSelectors.map((row) => (
								<TableRow key={row._id} hover>
									<TableCell component='th' scope='row'>
										<Link
											component='button'
											variant='body2'
											onClick={() => {
												history.push(`/productSelectors/${row._id}`);
											}}
										>
											{row._id}
										</Link>
									</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.slugs.map((slug) => slug)}</TableCell>

									<TableCell align='right'>
										<IconButton
											color='secondary'
											aria-label='Delete product Selector'
											component='span'
											onClick={() => handleDeleteProductSelector(row._id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
