import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import ImageInput from '../../components/ImageInput/ImageInput';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		width: '100%',
	},
	select: {
		flex: 1,
		marginRight: theme.spacing(2),
	},
	source: {
		flex: 3,
		marginRight: theme.spacing(2),
	},
}));

export default function SliderItem({ slide, onUpdate }) {
	const { type, sourceDesk, sourceMob, linkUrl } = slide;
	const classes = useStyles();
	const [sourceDeskValue, setSourceDeskValue] = useState(sourceDesk || '');
	const [sourceMobValue, setSourceMobValue] = useState(sourceMob || '');
	const [linkUrlValue, setLinkUrlValue] = useState(linkUrl || '');

	return (
		<Box className={classes.container}>
			<FormControl fullWidth className={classes.select}>
				<InputLabel>Type</InputLabel>
				<Select
					value={type}
					label='Type'
					onChange={(e) => {
						onUpdate({ type: e.target.value });
					}}
				>
					<MenuItem key={'image'} value={'image'}>
						Image
					</MenuItem>
					<MenuItem key={'video'} value={'video'}>
						Video
					</MenuItem>
				</Select>
			</FormControl>
			<ImageInput
				fullWidth
				label='Source Desktop'
				value={sourceDeskValue}
				helperText='2880x1050'
				className={classes.source}
				onChange={(e) => {
					setSourceDeskValue(e.target.value);
				}}
				onBlur={() => {
					onUpdate({ sourceDesk: sourceDeskValue });
				}}
			/>
			<ImageInput
				fullWidth
				label='Source Mob'
				value={sourceMobValue}
				helperText='800x930'
				className={classes.source}
				onChange={(e) => {
					setSourceMobValue(e.target.value);
				}}
				onBlur={() => {
					onUpdate({ sourceMob: sourceMobValue });
				}}
			/>
			<TextField
				fullWidth
				label='Link URL'
				value={linkUrlValue}
				helperText='https://...'
				className={classes.source}
				onChange={(e) => {
					setLinkUrlValue(e.target.value);
				}}
				onBlur={() => {
					onUpdate({ linkUrl: linkUrlValue });
				}}
			/>
		</Box>
	);
}
