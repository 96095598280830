import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as BenefitGroupsContext } from '../../context/BenefitGroupsContext';
import { Context as UserContext } from '../../context/UserContext';
import ImageInput from '../../components/ImageInput/ImageInput';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	textField: {
		marginBottom: theme.spacing(2),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function BenefitGroups({ onRender }) {
	const classes = useStyles();
	const history = useHistory();

	// STATE
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [benefitGroupToEdit, setBenefitToEdit] = useState(null);
	const [benefitGroupToDelete, setBenefitToDelete] = useState(null);
	const [name, setName] = useState('');
	const [image, setImage] = useState('');

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { benefitGroups, loading, error },
		loadBenefitGroups,
		createBenefitGroup,
		updateBenefitGroup,
		deleteBenefitGroup,
	} = useContext(BenefitGroupsContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadBenefitGroups();
		}

		onRender('Benefit Groups');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	async function handleCreateBenefitGroup() {
		await createBenefitGroup({ name, image });
		setName('');
		setImage('');
		setAddModalOpen(false);
	}

	function handleEditBenefitGroup(benefitGroup) {
		setEditModalOpen(true);
		setBenefitToEdit(benefitGroup);
		setName(benefitGroup.name);
		setImage(benefitGroup.image);
	}

	async function handleSaveBenefitGroup() {
		await updateBenefitGroup(benefitGroupToEdit._id, {
			name,
			image,
		});
		setName('');
		setImage('');
		setEditModalOpen(false);
	}

	function handleDeleteBenefitGroup(id) {
		setBenefitToDelete(id);
		setDeleteModalOpen(true);
	}

	if (error) {
		return <Error error={error} />;
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			{/* DELETE MODAL */}
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this benefitGroup group?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteBenefitGroup(benefitGroupToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			{/* ADD MODAL */}
			<Modal
				open={isAddModalOpen}
				onClose={() => {
					setAddModalOpen(false);
					setName('');
					setImage('');
				}}
			>
				<Box>
					<Typography className={classes.textField} variant='h6' gutterBottom>
						Add new benefit group
					</Typography>
					<TextField
						name='name'
						label='Name'
						value={name}
						className={classes.textField}
						onChange={(e) => setName(e.target.value)}
					/>
					<ImageInput
						label='Image'
						name='image'
						helperText='120x120'
						value={image}
						onChange={(e) => setImage(e.target.value)}
					/>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='secondary'
						onClick={() => {
							setAddModalOpen(false);
							setName('');
							setImage('');
						}}
					>
						Close
					</Button>
					<Button
						variant='contained'
						color='primary'
						style={{ marginLeft: '10px' }}
						onClick={() => handleCreateBenefitGroup()}
					>
						Add
					</Button>
				</Box>
			</Modal>
			{/* EDIT MODAL */}
			<Modal
				open={isEditModalOpen}
				onClose={() => {
					setEditModalOpen(false);
					setName('');
					setImage('');
				}}
			>
				<Box>
					<Typography className={classes.textField} variant='h6' gutterBottom>
						Edit benefit group
					</Typography>
					<TextField
						name='name'
						label='Name'
						value={name}
						className={classes.textField}
						onChange={(e) => setName(e.target.value)}
					/>
					<ImageInput
						label='Image'
						name='image'
						helperText='120x120'
						value={image}
						onChange={(e) => setImage(e.target.value)}
					/>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='secondary'
						onClick={() => {
							setEditModalOpen(false);
							setName('');
							setImage('');
						}}
					>
						Close
					</Button>
					<Button
						variant='contained'
						color='primary'
						style={{ marginLeft: '10px' }}
						onClick={() => handleSaveBenefitGroup()}
					>
						Save
					</Button>
				</Box>
			</Modal>

			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => setAddModalOpen(true)}
					>
						Add
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Image</TableCell>
								<TableCell align='right'>Edit / Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{benefitGroups.map((row) => (
								<TableRow key={row._id} hover>
									<TableCell component='th' scope='row'>
										<Link
											component='button'
											variant='body2'
											onClick={() => {
												history.push(`/benefitGroups/${row._id}`);
											}}
										>
											{row.name}
										</Link>
									</TableCell>
									<TableCell>
										<img src={row.image} alt={row.name} height='75' width='75' />
									</TableCell>

									<TableCell align='right'>
										<IconButton
											color='primary'
											aria-label='Edit benefit group'
											component='span'
											onClick={() => handleEditBenefitGroup(row)}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											color='secondary'
											aria-label='Delete benefit group'
											component='span'
											onClick={() => handleDeleteBenefitGroup(row._id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
