import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Context as MannaOrdersContext } from '../../context/OrdersContext';
import { Context as MannaAnalyticsContext } from '../../context/AnalyticsContext';

import Error from '../../components/Error/Error';
import DatePicker from '../../components/DatePicker/DatePicker';
import OrdersTable from './OrdersTable';

import { CircularProgress, IconButton, LinearProgress } from '@material-ui/core';
import { api } from '../../api/api';
import Analytics from './Analytics';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  ordersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    flex: 1,
  },
  filters: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  datePicker: {
    marginTop: theme.spacing(3),
  },
  searchByOrder: {
    alignSelf: 'flex-end',
  },
  apply: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const orderStatuses = ['Successful', 'Declined'];

export default function OrdersPage({ onRender }) {
  // VARS
  const classes = useStyles();
  const history = useHistory();
  const initStartDate = moment().set({ hour: 0, minute: 0, second: 0 }).toISOString();
  const initEndDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();
  // eslint-disable-next-line no-restricted-globals
  const parsedQuery = queryString.parse(location.search);

  // STATE
  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [exportingOrders, setExportingOrders] = useState(false);
  const [affIds, setAffIds] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);

  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
  });

  const [filters, setFilters] = useState({
    startDate: parsedQuery.startDate || initStartDate,
    endDate: parsedQuery.endDate || initEndDate,
    status: '',
    affid: '',
    noDuplicates: false,
    campaignId: '',
  });

  // CONTEXTS
  const {
    state: { orders, loading: ordersLoading, error: ordersError },
    loadOrders,
    deleteOrder,
  } = useContext(MannaOrdersContext);

  const {
    state: { analytics, loading: analyticsLoading, error: analyticsError },
    loadAnalytics,
  } = useContext(MannaAnalyticsContext);

  // EFFECTS
  useEffect(() => {
    onRender('Orders');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    const loadFiltersFromUrl = () => {
      // eslint-disable-next-line no-restricted-globals
      const parsedQuery = queryString.parse(location.search);

      setFilters({
        startDate: parsedQuery.startDate || initStartDate,
        endDate: parsedQuery.endDate || initEndDate,
        status: parsedQuery.status || '',
        affid: parsedQuery.affid || '',
        noDuplicates: parsedQuery.noDuplicates === 'true', // Convert to boolean
        campaignId: parsedQuery.campaignId || '',
      });

      setPagination({
        page: parseInt(parsedQuery.page, 10) || 0,
        limit: parseInt(parsedQuery.limit, 10) || 25,
      });

      loadAnalytics({
        startDate: parsedQuery.startDate || initStartDate,
        endDate: parsedQuery.endDate || initEndDate,
        status: parsedQuery.status || '',
        affid: parsedQuery.affid || '',
        noDuplicates: parsedQuery.noDuplicates === 'true',
        campaignId: parsedQuery.campaignId || '',
      });

      loadOrders({
        page: parseInt(parsedQuery.page, 10) || 0,
        limit: parseInt(parsedQuery.limit, 10) || 25,
        startDate: parsedQuery.startDate || initStartDate,
        endDate: parsedQuery.endDate || initEndDate,
        status: parsedQuery.status || '',
        affid: parsedQuery.affid || '',
        noDuplicates: parsedQuery.noDuplicates === 'true',
        campaignId: parsedQuery.campaignId || '',
      });
    };

    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAffIds = async () => {
      try {
        const response = await api.get('/affIds', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: filters,
        });
        setAffIds(response.data);
      } catch (error) {
        console.error('Error fetching affIds:', error);
      }
    };

    fetchAffIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCampaignIds = async () => {
      try {
        const response = await api.get('/campaignIds', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: filters,
        });
        setCampaignIds(response.data);
      } catch (error) {
        console.error('Error fetching campaignIds:', error);
      }
    };

    fetchCampaignIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateQueryParams() {
    const searchString = queryString.stringify({
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status,
      affid: filters.affid,
      noDuplicates: filters.noDuplicates,
      campaignId: filters.campaignId,
      page: pagination.page,
      limit: pagination.limit,
    });

    history.push({
      search: searchString,
    });
  }

  function handlePaginationChange(newPagination) {
    setPagination(newPagination);
    loadOrders({
      ...newPagination,
      ...filters,
    });
  }

  function handleFilterChange(name, value) {
    const newFilters = { ...filters, [name]: value };
    const newPagination = {
      page: 0,
      limit: 25,
    };

    setFilters(newFilters);
    setPagination(newPagination);
    setApplyButtonVisible(true);
  }

  function applyFilters() {
    setPagination({ page: 0, limit: pagination.limit }); // Reset page to 0 when applying filters
    updateQueryParams();

    loadAnalytics(filters);
    loadOrders({
      ...pagination,
      page: 0, // Reset page to 0 when applying filters
      ...filters,
    });

    setApplyButtonVisible(false);
  }

  function isFilterApplied() {
    return (
      !moment(filters.startDate).isSame(initStartDate, 'day') ||
      !moment(filters.endDate).isSame(initEndDate, 'day') ||
      filters.status !== '' ||
      filters.affid !== '' ||
      filters.noDuplicates !== false ||
      filters.campaignId !== ''
    );
  }

  function resetFilters() {
    const initialFilters = {
      startDate: initStartDate,
      endDate: initEndDate,
      status: '',
      affid: '',
      noDuplicates: false,
      campaignId: '',
    };

    setFilters(initialFilters);
    setPagination({ page: 0, limit: 25 });

    history.push({
      search: '', // Clear the query string in the URL
    });

    loadAnalytics(initialFilters);
    loadOrders({
      ...initialFilters,
      page: 0,
      limit: 25,
    });

    setApplyButtonVisible(false);
  }

  function renderStatusSelect() {
    function handleChange(e) {
      handleFilterChange('status', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='status' style={{ backgroundColor: '#fafafa' }}>
          Status
        </InputLabel>
        <Select labelId='status' value={filters.status} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {orderStatuses.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderAffSelect() {
    function handleChange(e) {
      handleFilterChange('affid', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='affiliate' style={{ backgroundColor: '#fafafa' }}>
          Affiliate
        </InputLabel>
        <Select labelId='affiliate' value={filters.affid} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {affIds.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderDuplicatesFilter() {
    function handleChange(e) {
      handleFilterChange('noDuplicates', e.target.checked);
    }

    return (
      <FormControlLabel
        control={
          <Switch
            checked={filters.noDuplicates}
            onChange={handleChange}
            name='duplicates-filter'
            color='primary'
          />
        }
        label='Filter duplicates'
      />
    );
  }

  function renderCampaignSelect() {
    function handleChange(e) {
      handleFilterChange('campaignId', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='campaign' style={{ backgroundColor: '#fafafa' }}>
          Campaign Id
        </InputLabel>
        <Select labelId='campaign' value={filters.campaignId} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {campaignIds.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  const handleExportOrders = async () => {
    setExportingOrders(true); // Start the export process and possibly show a loading indicator

    try {
      // Include responseType 'blob' because we expect a file to be returned
      const response = await api.get('/exportOrders', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: filters,
        responseType: 'blob',
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${moment(filters.startDate).format('lll')}-${moment(filters.endDate).format(
            'lll'
          )}manna-orders.csv`
        ); // Specify the filename for download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link element and releasing the blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setExportingOrders(false);
      } else {
        throw new Error('Failed to receive file data from server.');
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download file.'); // Optionally show a more user-friendly error message or notification
      setExportingOrders(false); // Ensure we reset the exporting state on error as well
    }
  };

  function renderPageContent() {
    if (ordersError || analyticsError) {
      return <Error error={ordersError || analyticsError} />;
    }

    if (ordersLoading) {
      return <LinearProgress />;
    }

    return (
      <Box>
        <Analytics data={analytics} loading={analyticsLoading} />
        <OrdersTable
          pagination={pagination}
          ordersTotal={orders.count}
          onPaginationChange={handlePaginationChange}
          orders={orders.data}
          onDeleteOrder={deleteOrder}
        />
      </Box>
    );
  }

  return (
    <Container className={classes.container}>
      <DatePicker
        className={classes.datePicker}
        maxDate={initEndDate}
        startDate={filters.startDate}
        endDate={filters.endDate}
        onStartDateChange={(date) => handleFilterChange('startDate', date)}
        onEndDateChange={(date) => handleFilterChange('endDate', date)}
      />
      <Box className={classes.ordersHeader}>
        <Typography variant='h5' component='h5' className={classes.title}>
          Orders ({orders.count})
          <IconButton
            disabled={exportingOrders}
            variant='contained'
            color='primary'
            component='span'
            onClick={handleExportOrders}
          >
            {exportingOrders ? <CircularProgress size={21} /> : <SaveAltIcon />}
          </IconButton>
        </Typography>
        <FormGroup row className={classes.filters}>
          {renderDuplicatesFilter()}
          {renderAffSelect()}
          {renderCampaignSelect()}
          {renderStatusSelect()}
        </FormGroup>
      </Box>
      <Box className={classes.apply}>
        {applyButtonVisible && (
          <Button
            variant='contained'
            color='primary'
            style={{
              width: '200px',
              marginLeft: '15px',
            }}
            onClick={() => applyFilters()}
          >
            Apply Filters
          </Button>
        )}
        {isFilterApplied() && (
          <Button
            variant='outlined'
            color='secondary'
            size='small'
            style={{
              marginLeft: '10px',
              padding: '5px 10px', // Smaller padding for a more compact button
              minWidth: '100px', // Smaller width
            }}
            onClick={() => resetFilters()}
          >
            Reset Filters
          </Button>
        )}
      </Box>
      {renderPageContent()}
    </Container>
  );
}
