/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Modal from '../../components/Modal/Modal';
import Typography from '@material-ui/core/Typography';

import { Context as UserContext } from '../../context/UserContext';
import { Context as UsersContext } from '../../context/UsersContext';
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
	},
	name: {
		display: 'flex',
		alignItems: 'flex-end',
		'& svg': {
			marginLeft: theme.spacing(1),
		},
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	error: {
		marginTop: theme.spacing(3),
		color: theme.palette.error.main,
		fontSize: '12px',
	},
}));

export default function UsersPage({ onRender }) {
	const classes = useStyles();
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isCreateModalOpen, setCreateModalOpen] = useState(false);
	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);

	const [modalUser, setModalUser] = useState({
		name: '',
		email: '',
		password: '',
		isSuperAdmin: false,
	});

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { users, loading: usersLoading, error: usersError },
		loadUsers,
		createUser,
		updateUser,
		deleteUser,
	} = useContext(UsersContext);

	// EFFECTS
	useEffect(() => {
		if (user?.user.isSuperAdmin) {
			loadUsers();
			onRender('Users');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	function handleUpdateModalUser(filed, value) {
		setModalUser({ ...modalUser, [filed]: value });
	}

	async function handleCreateClick() {
		await createUser(modalUser);
		setCreateModalOpen(false);
		setModalUser({
			name: '',
			email: '',
			password: '',
			isSuperAdmin: false,
		});
	}

	function handleUpdateClick(item) {
		setModalUser(item);
		setUpdateModalOpen(true);
	}

	async function handleUpdateUser() {
		await updateUser(modalUser._id, {
			name: modalUser.name,
			email: modalUser.email,
			password: modalUser.password,
			isSuperAdmin: modalUser.isSuperAdmin,
		});
		setUpdateModalOpen(false);
		setModalUser({
			name: '',
			email: '',
			password: '',
			isSuperAdmin: false,
		});
	}

	function handleDeleteClick(item) {
		setUserToDelete(item);
		setDeleteModalOpen(true);
	}

	if (!user?.user.isSuperAdmin) {
		return null;
	}

	return (
		<>
			{usersLoading && <LinearProgress />}
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete {userToDelete?.name}'s account?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteUser(userToDelete._id);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Modal open={isCreateModalOpen} onClose={() => setCreateModalOpen(false)}>
				<Box>
					<Typography variant='h6' className={classes.textField} gutterBottom>
						Create new user
					</Typography>
					<FormControl className={classes.textField} fullWidth>
						<InputLabel shrink id='demo-simple-select-placeholder-label-label'>
							Is Super Admin?
						</InputLabel>
						<Select
							labelId='offer-select'
							id='offer-select'
							value={modalUser.isSuperAdmin}
							onChange={(e) => handleUpdateModalUser('isSuperAdmin', e.target.value)}
						>
							<MenuItem value={true}>Yes</MenuItem>
							<MenuItem value={false}>No</MenuItem>
						</Select>
					</FormControl>
					<TextField
						label='Name'
						value={modalUser.name}
						onChange={(e) => handleUpdateModalUser('name', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Email'
						value={modalUser.email}
						onChange={(e) => handleUpdateModalUser('email', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Password'
						value={modalUser.password}
						onChange={(e) => handleUpdateModalUser('password', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					{usersError && <p className={classes.error}>{usersError}</p>}
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='secondary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setCreateModalOpen(false);
						}}
					>
						Close
					</Button>
					<Button variant='contained' color='primary' onClick={() => handleCreateClick()}>
						Create
					</Button>
				</Box>
			</Modal>
			<Modal open={isUpdateModalOpen} onClose={() => setUpdateModalOpen(false)}>
				<Box>
					<Typography variant='h6' className={classes.textField} gutterBottom>
						Update user {modalUser?.name}
					</Typography>
					<FormControl className={classes.textField} fullWidth>
						<InputLabel shrink id='demo-simple-select-placeholder-label-label'>
							Is Super Admin?
						</InputLabel>
						<Select
							labelId='offer-select'
							id='offer-select'
							value={modalUser.isSuperAdmin}
							onChange={(e) => handleUpdateModalUser('isSuperAdmin', e.target.value)}
						>
							<MenuItem value={true}>Yes</MenuItem>
							<MenuItem value={false}>No</MenuItem>
						</Select>
					</FormControl>
					<TextField
						label='Name'
						value={modalUser.name}
						onChange={(e) => handleUpdateModalUser('name', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Email'
						value={modalUser.email}
						onChange={(e) => handleUpdateModalUser('email', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					<TextField
						label='Password'
						value={modalUser.password}
						onChange={(e) => handleUpdateModalUser('password', e.target.value)}
						fullWidth
						className={classes.textField}
					/>
					{usersError && <p className={classes.error}>{usersError}</p>}
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='secondary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setUpdateModalOpen(false);
						}}
					>
						Close
					</Button>
					<Button variant='contained' color='primary' onClick={() => handleUpdateUser()}>
						Update
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => setCreateModalOpen(true)}
					>
						Create
					</Button>
				</Box>
				<TableContainer component={Paper} className={classes.paper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell align='right'>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((item, index) => (
								<TableRow key={index}>
									<TableCell>
										<div className={classes.name}>
											{item.name} {item.isSuperAdmin && <ManageAccountsIcon />}
										</div>
									</TableCell>
									<TableCell>{item.email}</TableCell>
									<TableCell align='right'>
										<IconButton
											color='primary'
											aria-label='Update user'
											component='span'
											onClick={() => handleUpdateClick(item)}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											color='secondary'
											aria-label='Delete user'
											component='span'
											onClick={() => handleDeleteClick(item)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
}
