import React, { useContext, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Paper,
	Snackbar,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Context as UserContext } from '../../context/UserContext';
import DatePicker from '../../components/DatePicker/DatePicker';
import Slide from './Slide';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(3),
	},
	controls: {
		marginBottom: theme.spacing(2),
	},
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	title: {
		marginTop: theme.spacing(2),
		fontWeight: 'bold',
		textAlign: 'center',
	},
	slide: {
		width: '100%',
		display: 'flex',
	},
	buttons: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	centerButton: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(3),
	},
	deleteButton: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

// Helper function
const formatDate = (date, startOfDay) => {
	const hour = startOfDay ? 0 : 23;
	const minute = startOfDay ? 0 : 59;
	const second = startOfDay ? 1 : 59;
	return moment(date).set({ hour, minute, second }).toISOString();
};

export default function BannersPage({ onRender }) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [banners, setBanners] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			onRender('Banners');
			// load banners from api
			loadBanners();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const loadBanners = async () => {
		try {
			const response = await api.get('/banners', {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			setBanners(response.data);
			setLoading(false);
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const createBanner = async () => {
		setLoading(true);
		const newBanner = {};

		try {
			await api.post('/banners', newBanner, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const createSlide = async (bannerId) => {
		setLoading(true);
		const newSlide = {};

		try {
			await api.post(`/banners/${bannerId}/slides`, newSlide, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const updateSlide = async (bannerId, slideId, update) => {
		setLoading(true);
		try {
			await api.patch(`/banners/${bannerId}/slides/${slideId}`, update, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const deleteSlide = async (bannerId, slideId) => {
		setLoading(true);
		try {
			await api.delete(`/banners/${bannerId}/slides/${slideId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const updateBanner = async (bannerId, update) => {
		setLoading(true);
		try {
			await api.patch(`/banners/${bannerId}`, update, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	const deleteBanner = async (bannerId) => {
		setLoading(true);
		try {
			await api.delete(`/banners/${bannerId}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			loadBanners();
		} catch (error) {
			setSnackbarMessage(error.response.data.error);
			setSnackbarOpen(true);
			setLoading(false);
		}
	};

	function handleAddBanner() {
		createBanner();
	}

	function handleAddSlide(bannerId) {
		createSlide(bannerId);
	}

	function handleUpdateSlide(bannerId, slideId, update) {
		updateSlide(bannerId, slideId, update);
	}

	function handleDeleteSlide(bannerId, slideId) {
		deleteSlide(bannerId, slideId);
	}

	const handleStartDateChange = useCallback((bannerId, date) => {
		updateBanner(bannerId, { startDate: formatDate(date, true) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEndDateChange = useCallback((bannerId, date) => {
		updateBanner(bannerId, { endDate: formatDate(date, false) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDeleteBanner = useCallback((bannerId) => {
		deleteBanner(bannerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading && <LinearProgress className={classes.progress} />}
			<Container maxWidth='lg' className={classes.container}>
				<Box display='flex' justifyContent='flex-end' className={classes.controls}>
					<Button variant='contained' color='primary' onClick={() => handleAddBanner()}>
						Add Banner
					</Button>
				</Box>

				{/* banners */}
				{banners.map((banner) => (
					<Paper key={banner._id} className={classes.paper}>
						{/* delete banner red button with text*/}

						<FormControlLabel
							control={
								<Checkbox
									checked={banner.default}
									onChange={(e) => updateBanner(banner._id, { default: e.target.checked })}
									name='default'
									color='primary'
								/>
							}
							label='Default'
						/>

						{!banner.default && (
							<DatePicker
								align='left'
								startDate={banner.startDate}
								endDate={banner.endDate}
								onStartDateChange={(date) => handleStartDateChange(banner._id, date)}
								onEndDateChange={(date) => handleEndDateChange(banner._id, date)}
							/>
						)}
						<Typography className={classes.title} variant='subtitle1' gutterBottom>
							Slides
						</Typography>

						<Box>
							{banner.slides.map((slide, index) => (
								<div className={classes.slide} key={index}>
									<Slide
										slide={slide}
										onUpdate={(update) => handleUpdateSlide(banner._id, slide._id, update)}
									/>
									<Box className={classes.buttons}>
										<IconButton
											color='secondary'
											aria-label='Delete'
											component='span'
											size='small'
											onClick={() => handleDeleteSlide(banner._id, slide._id)}
										>
											<DeleteIcon />
										</IconButton>
									</Box>
								</div>
							))}
						</Box>
						<Box className={classes.centerButton}>
							<Button
								size='small'
								variant='contained'
								color='primary'
								onClick={() => handleAddSlide(banner._id)}
							>
								Add Slide
							</Button>
						</Box>
						<Box className={classes.deleteButton}>
							<Button variant='contained' color='secondary' onClick={() => handleDeleteBanner(banner._id)}>
								Delete banner
							</Button>
						</Box>
					</Paper>
				))}
			</Container>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={() => setSnackbarOpen(false)}
				message={snackbarMessage}
				action={
					<React.Fragment>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={() => setSnackbarOpen(false)}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</React.Fragment>
				}
			/>
		</>
	);
}
