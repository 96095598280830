import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import { Context as StickyProductsContext } from '../../context/StickyProductsContext';
import { Context as UserContext } from '../../context/UserContext';
import { getDollarString } from '../../utils/helpers';
import axios from 'axios';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export default function StickyProducts({ onRender }) {
  const classes = useStyles();
  const [refreshing, setRefreshing] = useState(false);
  const [refreshError, setRefreshError] = useState('');

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { stickyProducts, loading, error },
    loadStickyProducts,
  } = useContext(StickyProductsContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadStickyProducts();
    }

    onRender('Sticky products');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function handleRefresh() {
    try {
      setRefreshing(true);
      setRefreshError('');
      await axios.get('https://manna.com/api/sticky/getProducts');
      setRefreshing(false);
      loadStickyProducts();
    } catch (error) {
      setRefreshing(false);
      setRefreshError('Error happened while syncing. Please try again');
    }
  }

  if (error) {
    return <Error error={error} />;
  }

  if (loading || refreshing) {
    return <Loading />;
  }

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.topButtons}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Button
              style={{
                alignSelf: 'flex-end',
                marginBottom: '10px',
              }}
              variant='contained'
              color='primary'
              startIcon={<RefreshIcon />}
              onClick={() => handleRefresh()}
            >
              Sync products
            </Button>
            {refreshError && (
              <Typography
                style={{
                  color: 'red',
                }}
                variant='caption'
                gutterBottom
              >
                {refreshError}
              </Typography>
            )}
          </Box>
        </Box>
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Updated at</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stickyProducts.map((row) => (
                <TableRow key={row._id} hover>
                  <TableCell>{row.updated_at && moment(row.updated_at.date).format('lll')}</TableCell>
                  <TableCell>
                    <Link
                      variant='body2'
                      target='_blank'
                      href={`https://manna.sticky.io/admin/products/products.php?product_id=${row.id}`}
                    >
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={row.active === '1'} color='primary' />
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{getDollarString(row.price)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
