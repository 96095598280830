import createDataContext from './createDataContext';
import { api } from '../api/api';

const offerReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				specialOffer: action.payload,
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadSpecialOffer = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get(`/specialOffers/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load special offer.',
		});
	}
};

const updateSpecialOffer = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/specialOffers/${id}`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to update special offer.',
		});
	}
};

const createSpecialOffer = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/specialOffers`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});

		return response.data;
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to create special offer.',
		});
	}
};

const deleteSpecialOffer = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.delete(`/specialOffers/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to delete special offer.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	offerReducer,
	{
		loadSpecialOffer,
		createSpecialOffer,
		updateSpecialOffer,
		deleteSpecialOffer,
	},
	{
		specialOffer: null,
		error: '',
		loading: false,
	}
);
