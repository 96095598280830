import React from 'react';
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	'multi-select__menu': {
		backgroundColor: 'red',
	},
}));

function arrayMove(array, from, to) {
	const slicedArray = array.slice();
	slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
	return slicedArray;
}

const SortableMultiValue = SortableElement((props) => {
	const onMouseDown = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const innerProps = { ...props.innerProps, onMouseDown };
	return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props) => <components.MultiValueLabel {...props} />);

const SortableSelect = SortableContainer(Select);

export default function MultiSelect({ options, value, onChange, label, optionLabel, className }) {
	const classes = useStyles();

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newValue = arrayMove(value, oldIndex, newIndex);
		onChange(newValue);
	};

	return (
		<div className={`${classes.root}  ${className}`}>
			<Typography variant='subtitle1'>{label}</Typography>
			<SortableSelect
				classNamePrefix='multi-select'
				useDragHandle
				axis='xy'
				onSortEnd={onSortEnd}
				distance={4}
				getHelperDimensions={({ node }) => node.getBoundingClientRect()}
				isMulti
				options={options}
				value={value}
				onChange={onChange}
				getOptionLabel={(option) => option[optionLabel]}
				getOptionValue={(option) => option.id || option._id}
				components={{
					MultiValue: SortableMultiValue,
					MultiValueLabel: SortableMultiValueLabel,
				}}
				closeMenuOnSelect={false}
			/>
		</div>
	);
}
