/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import { Context as WebsitesContext } from '../../context/WebsitesContext';
import { Context as UserContext } from '../../context/UserContext';
import { Link, Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
}));

export default function WebsitesPage({ onRender }) {
	const classes = useStyles();

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { websites, loading: websitesLoading, error: websitesError },
		loadWebsites,
	} = useContext(WebsitesContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadWebsites();
		}

		onRender('Websites');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (websitesError) {
		return <Error error={websitesError} />;
	}

	if (websitesLoading) {
		return <Loading />;
	}

	return (
		<Container className={classes.container}>
			<TableContainer component={Paper} className={classes.paper}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Verified</TableCell>
							<TableCell>URL</TableCell>
							<TableCell>Title</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{websites.map((row) => (
							<TableRow key={row._id} hover>
								<TableCell>
									<Typography variant='caption' display='block'>
										{moment(row.updatedAt).format('MM/DD/YY hh:mm:ss')}
									</Typography>
								</TableCell>
								<TableCell>
									<Link target='_blank' href={row.url}>
										{row.url}
									</Link>
								</TableCell>
								<TableCell>{row.title}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}
