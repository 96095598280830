import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	textField: {},
	container: {
		display: 'flex',
		width: '100%',
	},
	image: {
		marginLeft: theme.spacing(3),
	},
	select: {
		flex: 1,
		marginRight: theme.spacing(2),
	},
	source: {
		flex: 2,
		marginRight: theme.spacing(2),
	},
	thumbnail: {
		flex: 2,
	},
}));

export default function GalleryItem({
	index,
	type,
	thumbnail,
	source,
	onUpdate,
	sourceHelperText,
	thumbnailHelperText,
}) {
	const classes = useStyles();
	const [thumbnailValue, setThumbnailValue] = useState(thumbnail || '');
	const [sourceValue, setSourceValue] = useState(source || '');

	return (
		<Box className={classes.container}>
			<FormControl fullWidth className={classes.select}>
				<InputLabel>Type</InputLabel>
				<Select
					value={type}
					label='Type'
					onChange={(e) => {
						onUpdate({ type: e.target.value }, index);
					}}
				>
					<MenuItem key={'image'} value={'image'}>
						Image
					</MenuItem>
					<MenuItem key={'video'} value={'video'}>
						Video
					</MenuItem>
				</Select>
			</FormControl>
			<TextField
				label='Source'
				value={sourceValue}
				helperText={sourceHelperText}
				className={classes.source}
				onChange={(e) => {
					setSourceValue(e.target.value);
				}}
				onBlur={() => {
					onUpdate({ source: sourceValue }, index);
				}}
				fullWidth
			/>
			<TextField
				label='Thumbnail'
				value={thumbnailValue}
				helperText={thumbnailHelperText}
				onChange={(e) => {
					setThumbnailValue(e.target.value);
				}}
				onBlur={(e) => {
					onUpdate({ thumbnail: thumbnailValue }, index);
				}}
				fullWidth
				className={classes.thumbnail}
			/>
			<img src={thumbnail} height='38' alt='Thumbnail' className={classes.image} />
		</Box>
	);
}
