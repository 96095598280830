import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function Wysiwyg({ initialValue, onBlur }) {
	const editorRef = useRef(null);
	const [value, setValue] = useState(initialValue ?? '');
	useEffect(() => setValue(initialValue ?? ''), [initialValue]);

	return (
		<>
			<Editor
				apiKey='rx8tl7q04vo51sh9efk1jcattb3gf9u3t34ky1u511bq98xf'
				initialValue={initialValue}
				onInit={(evt, editor) => (editorRef.current = editor)}
				value={value}
				onEditorChange={(newValue, editor) => setValue(newValue)}
				onBlur={() => {
					onBlur(editorRef.current.getContent());
				}}
				init={{
					height: 500,
					menubar: true,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
					],
					toolbar:
						'undo redo | formatselect | ' +
						'bold italic backcolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
				}}
			/>
		</>
	);
}
