import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChipsList from '../../components/ChipsList/ChipsList';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@mui/material/Switch';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';

import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as CrossSellContext } from '../../context/CrossSellContext';
import { Context as StickyProductsContext } from '../../context/StickyProductsContext';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '100%',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
}));

export default function CrossSellPage({ onRender }) {
	// VARS
	const classes = useStyles();
	const { id } = useParams();

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { crossSell, loading: crossSellLoading, error: crossSellError },
		loadCrossSell,
		updateCrossSell,
	} = useContext(CrossSellContext);

	const {
		state: { stickyProducts, stickyProductsLoading, stickyProductsError },
		loadStickyProducts,
	} = useContext(StickyProductsContext);

	// EFFECTS
	useEffect(() => {
		if (user) {
			loadCrossSell(id);
			loadStickyProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (crossSell) {
			onRender(`CrossSell - ${crossSell.product?.name}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crossSell]);

	if (crossSellError || stickyProductsError) {
		return <Error error={crossSellError || stickyProductsError} />;
	}

	if (!crossSell) {
		return null;
	}

	function handleCrossSellUpdate(update) {
		updateCrossSell(id, update);
	}

	function renderProductSelect() {
		const options = stickyProducts.map((item) => ({
			value: item.id,
			label: `${item.name} [${item.id}]`,
		}));

		function handleChange(e) {
			const product = stickyProducts.find((item) => item.id === e.target.value);

			handleCrossSellUpdate({
				product,
			});
		}

		return (
			<FormControl fullWidth className={classes.textField}>
				<InputLabel>Sticky product</InputLabel>
				<Select value={crossSell.product?.id} label='Sticky product' onChange={handleChange}>
					{options.map((option, index) => (
						<MenuItem value={option.value} key={index}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}

	function renderActiveSelect() {
		return (
			<FormControlLabel
				className={classes.textField}
				marginNormal
				label='Active'
				labelPlacement='end'
				control={
					<Switch
						checked={crossSell.active}
						onChange={(e) =>
							handleCrossSellUpdate({
								active: e.target.checked,
							})
						}
						name='active'
					/>
				}
			/>
		);
	}

	function renderExcludeSelect() {
		return (
			<ChipsList
				items={crossSell.exclude}
				onUpdate={(newItems) => handleCrossSellUpdate({ exclude: newItems })}
				title='Exclude PIDs'
			/>
		);
	}

	return (
		<>
			{(crossSellLoading || stickyProductsLoading) && <LinearProgress className={classes.progress} />}
			<Container maxWidth='lg' className={classes.container}>
				<Paper className={classes.paper}>
					{renderActiveSelect()}
					{renderProductSelect()}
					{renderExcludeSelect()}
				</Paper>
			</Container>
		</>
	);
}

CrossSellPage.propTypes = {
	onRender: PropTypes.func,
};
