import createDataContext from './createDataContext';
import { api } from '../api/api';

const usersReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'SUCCESS':
			return {
				...state,
				loading: false,
				users: action.payload,
			};
		case 'CREATE_SUCCESS':
			return {
				...state,
				loading: false,
				users: [...state.users, action.payload.user],
			};
		case 'CREATE_ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case 'UPDATE_SUCCESS':
			return {
				...state,
				loading: false,
				users: state.users.map((user) => {
					if (user._id === action.payload._id) {
						return action.payload;
					}
					return user;
				}),
			};
		case 'UPDATE_ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case 'DELETE_SUCCESS':
			return {
				...state,
				loading: false,
				users: state.users.filter((user) => user._id !== action.payload._id),
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

const loadUsers = (dispatch) => async () => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.get('/users', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to load users',
		});
	}
};

const createUser = (dispatch) => async (data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.post(`/users`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		dispatch({
			type: 'CREATE_SUCCESS',
			payload: response.data,
		});

		return response.data;
	} catch (error) {
		const errorMessage = error.response ? error.response.data.message : 'Unknown error occurred';
		dispatch({
			type: 'CREATE_ERROR',
			payload: errorMessage,
		});
		throw new Error(errorMessage);
	}
};

const updateUser = (dispatch) => async (id, data) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.patch(`/users/${id}`, data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'UPDATE_SUCCESS',
			payload: response.data,
		});
		return response.data;
	} catch (error) {
		const errorMessage = error.response ? error.response.data.message : 'Unknown error occurred';
		dispatch({
			type: 'UPDATE_ERROR',
			payload: errorMessage,
		});
		throw new Error(errorMessage);
	}
};

const deleteUser = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'REQUEST',
		});
		const response = await api.delete(`/users/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		dispatch({
			type: 'DELETE_SUCCESS',
			payload: response.data,
		});
	} catch (e) {
		dispatch({
			type: 'ERROR',
			payload: 'Unable to delete user.',
		});
	}
};

export const { Context, Provider } = createDataContext(
	usersReducer,
	{
		loadUsers,
		createUser,
		updateUser,
		deleteUser,
	},
	{
		users: [],
		error: false,
		loading: false,
	}
);
