import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import './DatePicker.sass';

const useStyles = makeStyles((theme) => ({
	picker: {
		marginRight: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginRight: 0,
		},
	},
}));

export default function DatePicker({
	onStartDateChange,
	onEndDateChange,
	startDate,
	endDate,
	maxDate,
	align,
	className,
	disabled,
}) {
	const classes = useStyles();

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container justifyContent={align === 'left' ? 'flex-start' : 'flex-end'} className={className}>
				<KeyboardDatePicker
					className={classes.picker}
					inputVariant='outlined'
					disableToolbar
					variant='inline'
					format='MM/dd/yyyy'
					maxDate={maxDate}
					margin='normal'
					id='start-date-picker'
					autoOk
					label='Start Date'
					value={startDate}
					onChange={onStartDateChange}
					KeyboardButtonProps={{
						'aria-label': 'start date',
					}}
					disabled={disabled}
				/>
				<KeyboardDatePicker
					disableToolbar
					inputVariant='outlined'
					variant='inline'
					format='MM/dd/yyyy'
					maxDate={maxDate}
					margin='normal'
					id='end-date-picker'
					autoOk
					label='End Date'
					value={endDate}
					onChange={onEndDateChange}
					KeyboardButtonProps={{
						'aria-label': 'end date',
					}}
					disabled={disabled}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}
