import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import MainMenu from '../MainMenu/MainMenu';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	offset: theme.mixins.toolbar,
}));

export default function Header({ pageName, user, onLogout }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	function handleClose() {
		setAnchorEl(null);
	}

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleLogout() {
		onLogout(user.user._id);
		handleClose();
	}

	if (!user) {
		return null;
	}

	return (
		<>
			<MainMenu isOpen={open} onSetOpen={(state) => setOpen(state)} />
			<Box className={classes.root}>
				<AppBar position='fixed'>
					<Toolbar>
						<IconButton
							edge='start'
							className={classes.menuButton}
							color='inherit'
							aria-label='menu'
							onClick={() => setOpen(true)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant='h6' className={classes.title}>
							{pageName}
						</Typography>
						<Button color='inherit' aria-controls='user-menu' aria-haspopup='true' onClick={handleClick}>
							{user && user.user.name}
						</Button>
						<Menu
							id='user-menu'
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={() => handleLogout()}>Log out</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
				<div className={classes.offset} />
			</Box>
		</>
	);
}
